//**************************************************************
// 1. scroll fadein animation
//**************************************************************
// $(window).scroll(function () {
//   $('.fadeInUp,.fadeInRight,.fadeInLeft,.fadeIn').each(function() {
//     var elemPos = $(this).offset().top,
//       scroll = $(window).scrollTop(),
//       windowHeight = $(window).height();

//     if (scroll > elemPos - windowHeight + 100) {
//       $(this).addClass('scrollin');
//     }
//   });
// });


//**************************************************************
// 3. Smooth Scroll （TOPに戻るボタン用）
//**************************************************************
$(function () {
  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, 'linear');
    return false;
  });
});


//**************************************************************
// 4. スクロールしたら メニューを隠す
//**************************************************************
var startPos = 0, winScrollTop = 0;
$(window).on('scroll', function () {
  winScrollTop = $(this).scrollTop();
  if (winScrollTop >= startPos) {
    if (winScrollTop >= 200) {
      $('.site-header').addClass('hide');
    }
  } else {
    $('.site-header').removeClass('hide');
  }
  startPos = winScrollTop;
});


//**********************************************************************
// 5. ハンバーガーメニューの起動
//**********************************************************************
$(function () {
  $('.hamburger').click(function () {
    $(this).toggleClass('active');

    if ($(this).hasClass('active')) {
      $('.menu-contents').addClass('active');
    }
    else {
      $('.menu-contents').removeClass('active');
    }
  });
});


//**********************************************************************
// 6. ハンバーガーメニューのメニュークリック後に 閉じる
//**********************************************************************
$(function () {
  $('.modal-close').on('click', function (event) {
    $('.menu-contents').removeClass('active');
  });
});

$(function () {
  $('.hum-close').on('click', function (event) {
    $('.hamburger').removeClass('active');
  });
});


//**********************************************************************
// 7. ナビゲージョンのCurrent表示
//**********************************************************************
$(function () {

  var pageURL = location.pathname,
    pageURLArr = pageURL.split('/'), //パスを分割して配列化する
    pageURLArrCategory = pageURLArr[1]; //パスから第1階層を取得

  $('.navigation li a').each(function (i, v) {
    var selfhref = $(v).attr('href'),
      hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
      hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得

    //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
    if (pageURLArrCategory === hrefArrCategory) {
      $(v).addClass('current');
    }

  });
});


//**********************************************************************
// FADE IN ANIMATION（画面遷移）
//**********************************************************************
// $(window).on('load', function(){
//   $('body').removeClass('fadeout');
// });

// $(function() {
//   // ハッシュリンク(#)と別ウィンドウでページを開く場合はスルー
//   $('a:not([href^="#"]):not([target]):not(#kitchen-photos"])').on('click', function(e){
//     e.preventDefault(); // ナビゲートをキャンセル
//     url = $(this).attr('href'); // 遷移先のURLを取得
//     if (url !== '') {
//       $('body').addClass('fadeout');  // bodyに class="fadeout"を挿入
//       setTimeout(function(){
//         window.location = url;  // 0.8秒後に取得したURLに遷移
//       }, 800);
//     }
//     return false;
//   });
// });





//**********************************************************************
// モーダルの動画の自動停止
//**********************************************************************
const modals = document.getElementsByClassName('movie-modal');

Array.from(modals).forEach((modal) => {
  modal.addEventListener('hidden.bs.modal', function () {
    console.log('モーダル閉じた');

    const iframes = modal.getElementsByTagName('iframe');
    Array.from(iframes).forEach((iframe) => {
      const src = iframe.src;
      iframe.src = '';
      setTimeout(function () {
        iframe.src = src;
      }, 100);
    });
  });
});


//**********************************************************************
// 独り言の表示
//**********************************************************************
window.onload = function() {
  // jsonファイルを取得
  fetch('/wp-content/themes/hiroshi-ihara/assets/json/phrases.json')
      .then(response => response.json())
      .then(data => {
          const phrases = data.phrases;

          // 各グループごとに異なる文言を取得
          const group1 = getRandomPhrase(phrases);
          const group2 = getRandomPhrase(phrases.filter(phrase => phrase !== group1));
          const group3 = getRandomPhrase(phrases.filter(phrase => phrase !== group1 && phrase !== group2));

          // 同じ文言を randomPhrase1 と randomPhrase2 に表示
          displayRandomPhrase('randomPhrase1', group1);
          displayRandomPhrase('randomPhrase2', group1);

          // 同じ文言を randomPhrase3 と randomPhrase4 に表示
          displayRandomPhrase('randomPhrase3', group2);
          displayRandomPhrase('randomPhrase4', group2);

          // 同じ文言を randomPhrase5 と randomPhrase6 に表示
          displayRandomPhrase('randomPhrase5', group3);
          displayRandomPhrase('randomPhrase6', group3);
      })
      .catch(error => console.error('JSONファイルの読み込みに失敗しました', error));

  // ランダムな文言を取得する関数
  function getRandomPhrase(phrases) {
      const randomIndex = Math.floor(Math.random() * phrases.length);
      return phrases[randomIndex];
  }

  // ランダムな文言を表示する関数
  function displayRandomPhrase(elementId, phrase) {
      document.getElementById(elementId).innerText = phrase;
  }
}




